<script setup lang="ts">
import appConfig from "~/app.config";

const route = useRoute();

const shouldExcludeCanonical =
  route.path.includes("/faq/candidats/") ||
  route.path.includes("/faq/recruteurs/") ||
  route.fullPath.includes("?");

useHead(() => {
  const links = [];

  if (!shouldExcludeCanonical) {
    links.push({
      rel: "canonical",
      href: `https://www.extracadabra.com${route.path}`,
    });
  }
  return {
    link: links,
  };
});
</script>

<template>
  <main class="default-main">
    <slot name="nav" />

    <slot name="content-header" />

    <div class="default-layout container">
      <slot />
    </div>
    <slot name="full-size-bottom" />
    <slot name="content-footer" />

    <FooterBig :footer-data="appConfig.footer" />
  </main>
</template>
<style lang="scss">
.default-main {
  padding-top: $menu-height;
  &.metro {
    padding-top: calc($menu-height + 20px);
  }
}
.fullwidth {
  .default-layout.container {
    padding: 0;
    max-width: 100%;
    margin: 0 auto;
    position: relative;
    height: 100%;
    width: 100%;
  }
}
</style>
